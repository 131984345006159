@use '../../variables';

.invitation-section{
    background-color: #E5E6E8;
    width: 100%;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 50vh;
}
.invitation-section h3{
    color: black;
    // font-family: variables.$font-bold;
    font-family: 'Philosopher';
    font-weight: 400;
    font-size: 3rem;
}
.invitation-section a{
    color: white;
    border-radius: 8px;
    // font-family: variables.$font-regular;
    font-family: 'Poppins';
    font-size: 1rem;
    text-decoration: none;
    font-weight: 400;
    padding: .8rem 2rem;
    background-color: rgb(0, 61, 63);
    margin-top: 1rem;
}
