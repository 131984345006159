@use '../../variables';

footer{
    display: flex;
    background-color: rgb(17, 16, 16);
    padding: 4rem 2rem;
    flex-wrap: wrap;
}
footer .page-links-div{
    flex-basis: 30%;
    border-right: 1px solid white;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
footer .page-links-div ul li{
    margin: 1rem 0rem;
    list-style-type: none;
}
footer .page-links-div ul li .link{
    // font-family: variables.$font-regular;
    font-family: 'Poppins';
    font-size: 1rem;
    // font-size: 1.1rem;
    color: white;
    text-decoration: none;
}
footer .credits-div{
    flex-basis: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
footer .credits-div h3{
    color: white;
    // font-family: variables.$font-regular;
    font-family: 'Philosopher';
    font-size: 2rem;
    font-weight: 400;
}
footer .credits-div h4{
    color: rgba(255, 255, 255, 0.795);
    font-family: 'oswald';
    font-size: 1rem;
    font-weight: 400;
    display: none;
}
footer .credits-div h4 a{
    color: rgba(255, 255, 255, 0.795);    
    font-family: 'oswald';
    font-size: 1rem;
    font-weight: 400;
}
footer .social-links-div{
    flex-basis: 30%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-left: 1px solid white;
}
footer .social-links-div ul li{
    margin: 1rem 0rem;
    list-style-type: none;
}
footer .social-links-div ul li a{
    // font-family: variables.$font-regular;
    font-family: 'Poppins';
    font-size: 1rem;
    color: white;
    text-decoration: none;
}
.map-div{
    width: 100vw;
    margin-bottom: 8rem;
    display: flex;
    justify-content: flex-end;
}
.map-div iframe{
    width: 50%;
}
.map-heading-div{
    width: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.map-heading-div h4{
    color: white;
    // font-family: variables.$font-regular;
    font-family: 'Philosopher';
    font-size: 3rem;
    font-weight: 400;
}