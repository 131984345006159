@use '../../_variables';

.testimonials-section-home {
    width: 100%;
    display: flex;
    // height: 100vh;
    justify-content: flex-start;
    background-color: #E5E6E8;
    position: relative;
    flex-direction: column;

 
}

.testimonials-section-home h3 {
    // font-family: variables.$font-bold;
    font-family: 'Philosopher';
    font-size: 3.2rem;
    color: black;
    font-weight: 400;
    text-align: center;
}

.testimonial-container {
    width: 100%;
    margin: 5rem 0rem 0rem 0rem;
    position: relative;
    display: flex;
    justify-content: center;
    min-height: 100vh;
    background: linear-gradient(rgba(0, 0, 0, 0.349),rgba(0, 0, 0, 0.801)) , url('https://images.pexels.com/photos/5005252/pexels-photo-5005252.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1');
    background-size: cover;
    background-position: center center;
    align-items: center;
    overflow-x: hidden;
    background-attachment: fixed;
}

.testimonial-div {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    flex-direction: column;
    padding: 4rem 1.5rem;
    transition: .8s;
    /* background-color: rgb(17, 16, 16); */
    background: linear-gradient(360deg,rgba(0, 0, 0, 0.274),rgba(0, 0, 0, 0.986));
    width: 80%;
    margin-top: 5rem;
    border-radius: 18px;
}

.testimonial-content-div {
    background-color: black;
    width: 80%;
    padding: 4rem 1.5rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    border-radius: 5px;
    position: relative;
    transition: .8s;
}

.testimonial-content-div{
    padding-top: 2rem;
}
.testimonial-div h4{
    padding-top: 1rem;
    // font-family: variables.$font-regular;
    color: white;
    font-family: 'Poppins';
    font-size: 1.2rem;
    font-weight: 400;
}
.testimonial-div h5{
    font-family: 'Poppins';
    font-size: .9rem;
    font-weight: 300;
    // font-family: variables.$font-regular;
    // color: rgba(255, 255, 255, 0.836)
    color: white;
}
.testimonial-div p {
    color: white;
    // font-size: 1.1rem;
    width: 100%;
    font-family: 'Poppins';
    font-weight: 300;
    text-align: center;
    // font-family: variables.$font-regular;
}


.testimonial-img-div{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    display: none;
    justify-items: center;
    position: absolute;
    right: 0;
    top: 0;
    margin-top: -3rem;
    border: 2px solid white;

}
.testimonial-img-div img{
    width: 200%;
}