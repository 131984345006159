html{
  width: 100%;
  height: 100%;
}
body {
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "denish-bold";   /*Can be any text*/
  src: local("denish-bold"),
    url("./fonts/Denish-Bold.otf") format("opentype");
}

@font-face {
  font-family: "denish-medium";   /*Can be any text*/
  src: local("denish-medium"),
    url("./fonts/Denish-Medium.otf") format("opentype");
}

@font-face {
  font-family: "denish-regular";   /*Can be any text*/
  src: local("denish-regular"),
    url("./fonts/Denish-Regular.otf") format("opentype");
}

@font-face {
  font-family: "denish-light";   /*Can be any text*/
  src: local("denish-light"),
    url("./fonts/Denish-Light.otf") format("opentype");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* overflow-x: hidden; */
}
.flex{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.column{
  flex-direction: column;
}
/* width */
/* ::-webkit-scrollbar {
  width: 10px;
  display: none;
} */

/* Track */
/* ::-webkit-scrollbar-track {
  background: #E5E6E8;
  padding: 0rem .5rem
}
  */
/* Handle */
/* ::-webkit-scrollbar-thumb {
  background: black; 
} */

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: #555; 
} */