.header-global {
    width: 100%;
    padding: 2rem;
    background-color: #e5e6e8;
    border-bottom: 2px solid black;
}
.header-global  .logo-div h2{
    color: black;
    font-size: 2rem;
    font-family: 'Oswald';
}
.header-global  .links-icon-div{
    cursor: pointer;

}
.header-global  .links-icon-div .links-icon-line{
    background-color: black;
    margin: .2rem 0rem;
    height: .2rem;
    display: inline-block;
}
.header-global  .links-icon-div .links-icon .links-icon-first-line{
    width: 5rem;
}
.header-global  .links-icon-div .links-icon .links-icon-second-line{
    width: 4rem;
}
