.about-second-section{
    width: 100%;
    background-color: #e5e6e8;
    padding: 2rem;

    div{
        width: 100%;
    }
    .first-div{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 2rem 0rem;

        h4{
            color: black;
            font-family: 'PT Serif';
            font-size: 2rem;
            margin: 0rem 2rem;
        }
    }
    .second-div{
        padding: 2rem 0rem;
        min-height: 100vh;
        max-height: 200vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        h3{
            color: black;
            font-family: 'PT Serif';
            text-align: center;
            font-size: 3rem;
        }
        p{
            color: black;
            font-family: 'poppins';
            font-size: 1.1rem;
            font-weight: 500;
            margin: 3rem 0rem;
            text-align: center;

        }
    }
    .third-div{
        padding: 2rem 0rem;
        h3{
            color: black;
            font-family: 'PT Serif';
            text-align: center;
            font-size: 3rem;
        }
    }
}

// .about .second-section .first-div{
//     display: flex;
//     justify-content: center;
//     margin: 5rem 0rem;
//     align-items: center;
//     flex-direction: column;
//     width: 100%;
//     padding: 0rem 2rem;

// }
/* .about .second-section .first-div h3{

        font-family: 'PT Serif';
        color: black;
        font-size: 2.5rem;
        margin-bottom: 1rem;
        text-align: center;
   
} */
// .about .second-section .first-div p{

//     font-family: 'PT Serif';
//     color: black;
//     font-size: 1.2rem;
//     width: 70%;
//     text-align: center;

// }
// .about .second-section .second-div{
//     display: flex;
//     justify-content: center;
//     margin-top: 5rem;
//     align-items: center;
//     flex-direction: column;
//     width: 100%;
// }
// .about .second-section .second-div h3{

//         font-family: 'PT Serif';
//         color: black;
//         font-size: 2.5rem;
//         margin-bottom: 1rem;
//         text-align: center;
   
// }
// .about .second-section .second-div p{

//     font-family: 'PT Serif';
//     color: black;
//     font-size: 1.2rem;
//     width: 70%;
//     text-align: center;

// }