.videos-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 4rem;
    flex-wrap: wrap;
}
.videos-container div{
    margin: 1rem;
}