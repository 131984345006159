.portfolio-content-section{
    width: 100%;
    padding: 2rem;
    background-color: #e5e6e8;

    h3{
        color: black;
        font-size: 3rem;
        font-family: 'PT Serif';
        text-align: center;
        margin: 1rem 0rem;
    }
    p{
        color: black;
        font-family: 'poppins';
        margin: 2rem 5rem;
        font-weight: 400;
        text-align: center;
        
    }
    div{
        display: flex;
        justify-content: center;
        align-items: center;
          margin: 3rem 0rem;
        width: 100%;
     
        button{
            outline: none;
            border: none;
            background-color: #e5e6e8;
            color: black;
            margin: 0rem 1rem;
            font-size: 1.2rem;
            font-family: 'poppins';
            padding: .5rem 1rem;
            border-radius: 5px;
            transition: .5s;
            cursor: pointer;
        }
       .add-btn-bg{
            background-color: rgb(0, 61, 63);;
            color: white;
          
        }
    }

}