@use '../../_variables';

.process-section{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* background-color: rgb(17, 16, 16); */
    background-image: linear-gradient(90deg, rgb(0, 104, 104),rgb(1, 37, 37)); 
    width: 100%;
    min-height: 100vh;
    padding: 3rem 0rem;
}
.process-section .process-section-heading{
    width: 100%;
}
.process-section .process-section-heading h3{
    // font-family: variables.$font-regular;
    font-family: 'Philosopher';
    font-size: 3rem;
    text-align: center;
    color: white;
}
.process-section .process-steps-wrapper{
    width: 100%;
    justify-content: space-between;
    display: flex;
    padding: 0rem 2rem;
    align-items: flex-start;
}
.process-section .process-steps-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-basis: 50%;
    position: relative;
}
.process-section .process-steps-container .process-step-div{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
}
.process-section .process-steps-container .process-step-div .step-number-span{
    background-color: #131313;
    color: white;
    /* border: 2px solid black; */
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    align-items: center;
    margin: 1rem;
}
.process-section .process-steps-container .process-step-div .process-step-content{
    // font-family: variables.$font-regular;
    font-family: 'Poppins';
    color: white;
    font-weight: 300;
    margin: 1rem;
    // font-size: 1.1rem;
}

.question-mark-container{
    flex-basis: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    z-index: 1000;
    padding-right: 2rem;
}
.question-mark{
    /* color: white; */
    width: 250px;
    position: relative;
    height: 250px;
    border-radius: 50%;
    background-color: #E5E6E8;
    display: flex;
    justify-content: center;
    align-items: center;
    /* font-size: 30rem; */

}

.question-mark p{
    color:rgb(17, 16, 16);
    -webkit-text-stroke: 1.5px #E5E6E8;
    font-size: 30rem;
}