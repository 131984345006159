.contact-section{
    width: 100vw;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    background-color: #E5E6E8;

}
main{
    background-color: #e5e6e8;
}
.contact-section > h3{
    font-family: 'PT Serif';
    font-size: 3rem;
    margin: 5rem 0rem 10rem 2rem;
}
.contact-section .content{
    background-color: rgb(0, 56, 56);
    width: 100%;
    padding: 3rem;
    margin-bottom: 10rem;
    display: flex;
    justify-content: space-between;
    position: relative;
    align-items: center;
}
.contact-section .content .extra-info{
   
    width: 50%;
    padding-right: 4rem;
}
.contact-section .content .extra-info-columns{
    margin: 2rem 0rem;

}
.contact-section .content .extra-info-columns h3{
    font-family: 'PT Serif';
    color: white;
    font-size: 1.5rem;
    margin-bottom: 1rem;
}
.contact-section .content .extra-info-columns p{
    font-family: 'PT Serif';
    color: white;
}
.contact-section .content .extra-info .social-icons{
    width: 100%;
}
.contact-section .content .extra-info .social-icons a{
    color: white;

}
.contact-section .content .extra-info .social-icons .icons{
    font-size: 30px;
    margin: 0rem 1rem;
    transition: .5s;
}
.contact-section .content .extra-info .social-icons .fa-facebook{
    margin-left: 0 !important;
}
.contact-section .content .extra-info .social-icons .icons:hover{
   color: teal;
}
.contact-section .contact-form{
    width: 40%;
    background-color: #131313;
    padding: 2rem;
    border-radius: 5px;
    position: absolute;
    right: 0;
    margin-right: 5rem;
    /* top: 0; */

}
.contact-section form{
    display: flex;
    flex-direction: column;
}
.contact-section form input{
    background-color: #131313;
    color: white;
    font-family: 'PT Serif';
    border: none;
    padding: 1rem;
    border-bottom: 1px solid white;
    margin: 1rem 0rem;
}
.contact-section form textarea{
    background-color: #131313;
    color: white;
    font-family: 'PT Serif';
    /* border: none; */
    padding: 1rem;
    border: 1px solid white;
    margin: 2rem 0rem;
    border-radius: 5px;
}
.contact-section form > div{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.contact-section form button{
    width: 25%;
    border-radius: 8px;
    font-weight: 400;
    font-size: 1rem;
    padding: 1rem 0rem;
    background-color: rgb(2, 77, 77);
    color: white;
    font-family: 'Poppins';
    cursor: pointer;
    outline: none;
    border: none;
}