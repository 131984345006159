
nav{
    /* background-color: blue; */
    width: 100%;
    position: fixed;
    z-index: 2000;
    height: 100vh;
    display: flex;
    /* visibility: hidden; */
    display: none;
}
nav .columns{
    height: 100vh;
    width: 25vw;
    /* background-color: rgb(17, 16, 16); */
    /* background-color: #A1CCD1; */
    background-color: rgb(0, 90, 90);
    transform: translateY(-100vh);
    transition: transform .5s;
    z-index: 2100;
}
.nav-content-container{
    /* position: absolute; */
    width: 100%;
    height: 100%;
    /* background-color: rgb(17, 16, 16); */
    /* background-color: rgb(0, 90, 90); */
    display: none;
    justify-content: center;
    background-color: rgb(0, 90, 90);
    align-items: center;
  
    z-index: 2200;
}
.nav-content-div{
    width: 100%;
    height: 100%;
    color: white;
    font-family: 'poppins';
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    opacity: 0;
    transition: opacity 1s;
}
.nav-content-div .first-section{
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding: 2rem;
}

.nav-content-div .first-section .logo-div h2{
    color: white;
    font-size: 2rem;
    font-family: 'Oswald';
    font-weight: 400;
}
.nav-content-div  .first-section .links-icon-div{
    cursor: pointer;

}
.nav-content-div  .first-section .links-icon-div .links-icon-line{
    background-color: white;
    margin: .2rem 0rem;
    height: .1rem;
    display: inline-block;
}
.nav-content-div  .first-section .links-icon-div .links-icon .links-icon-first-line{
    width: 5rem;
}
.nav-content-div  .first-section .links-icon-div .links-icon .links-icon-second-line{
    width: 4rem;
}

.nav-content-div .second-section{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
}
.nav-content-div .second-section > div{
    flex-basis: 50%;
}
.nav-content-div .second-section .page-links-div{
    display: flex;
    justify-content: flex-start;

}
.nav-content-div .second-section .page-links-div ul li{
    margin: 1rem 0rem;
    list-style-type: none;
    transition: transform .5s;
}

.nav-content-div .second-section .page-links-div ul li a{
    font-family: 'poppins';
    color: white;
    font-size: 3rem;
    text-decoration: none;
    transition: .5s;
}
.nav-content-div .second-section .page-links-div ul li a:hover{
    color: black;
}
.nav-content-div .second-section .info-div{
    display: flex;
    flex-direction: column;
}
.nav-content-div .second-section .info-div .social-div{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 2rem;
}
.nav-content-div .second-section .info-div .social-div h3{
    font-family: 'oswald';
    font-size: 2rem;
    color: white;
    font-weight: 400;
}
.nav-content-div .second-section .info-div .social-div .icons-div{
    margin-top: 1rem;
}
.nav-content-div .second-section .info-div .social-div .icons-div a{
    color: white;
}
.nav-content-div .second-section .info-div .social-div .icons{
    font-size: 25px;
    margin: 0rem .5rem;
}
.nav-content-div .second-section .info-div .social-div .icons:hover{
   color: teal;
}
.nav-content-div .second-section .info-div .address-div{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 2rem;
}
.nav-content-div .second-section .info-div .address-div h3{
    font-family: 'oswald';
    font-size: 2rem;
    color: white;
    font-weight: 400;
}