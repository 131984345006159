@use '../../_variables';

.header-home{
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
}



.header-home .video-div{    
    width: 100%;
    position: absolute;
    top: 0;
    height: 100%;
    left: 0;
}

.header-home .video-div video{
    object-fit: cover;
    width: 100%;
    height: 100%;
    
    z-index: 31;
}

.header-home .overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    /* background: linear-gradient(rgb(0 0 0 / 45%),#000000db); */
    background: linear-gradient(rgba(0, 0, 0, 0.28),#000000 );
    z-index: 32;
} 

/* first section */



.header-home .first-section{
    width: 100%;
    padding: 2rem;
    z-index: 50;
}
.header-home .first-section .logo-div h2{
    color: white;
    font-size: 2.5rem;
    // font-family: variables.$font-bold;
    font-family: 'Philosopher';
    text-shadow: 2px 2px 4px black;
    font-weight: 400;
}
.header-home .first-section .links-icon-div{
    cursor: pointer;

}
.header-home .first-section .links-icon-div .links-icon-line{
    background-color: white;
    box-shadow: 2px 2px 4px black;
    margin: .2rem 0rem;
    height: 0.12rem;
    display: inline-block;
}
.header-home .first-section .links-icon-div .links-icon .links-icon-first-line{
    width: 5rem;
}
.header-home .first-section .links-icon-div .links-icon .links-icon-second-line{
    width: 4rem;
}

@media screen and (max-width: 500px){
    .header-home .first-section{
        padding: 2rem 1rem;
    }
    .header-home .first-section .logo-div h2{

        font-size: 2rem;
    
    }
    .header-home .first-section .links-icon-div .links-icon-line{
        height: 0.1rem;
       
    }
}

/* second section */

.header-home .second-section{
     z-index: 50;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 2rem;
    margin-top: 8rem;
}

.header-home .second-section div{
    width: 70%;
    /* background-color: rgba(0, 0, 0, 0.671); */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // padding: 2rem;
    border-radius: 8px;
}
.header-home .second-section .second-column{
    display: none;
}

.header-home .second-section div h3{
    color: white;
    // font-family: variables.$font-bold;
    font-family: 'Philosopher';
    font-weight: 400;
    font-size: 3rem;
    text-align: center;
    // text-shadow: 2px 2px 4px black;
}
.header-home .second-section div p{
    color: white;
    // font-family:  variables.$font-regular;
    font-family: 'Poppins';
    margin-top: 1rem;
    font-size: 1.2rem;
    text-align: center;
    font-weight: 300;
    // text-shadow: 2px 2px 4px black;
}
.more-about-us-link{
    background-color: rgb(0, 61, 63);
    color: white;
    border: 1px solid rgb(0, 61, 63);
    text-decoration: none;
    padding: .8rem 2rem;
    font-family: 'poppins';
    border-radius: 10px;
    font-weight: 500;
    font-size: .9rem;
    margin-top: 1rem;
    transition: .5s;
}

@media screen and (max-width: 1000px) {
    .header-home .second-section .second-column{
        display: flex;
    }
    .header-home .second-section{
        flex-direction: column;

        align-items: flex-start;
    }
    .header-home .second-section div{
        width: 100%;
        justify-content: center;
        align-items: center;
    }
    .header-home .second-section div p{
        text-align: center;
    }
    .header-home .second-section div h3{
        text-align: center;
    }
}
@media screen and (max-width: 700px){
    .header-home .second-section div h3{
       font-size: 2.5rem;
    }
    .header-home .second-section div p{
        font-size: 1rem;
    }
}
@media screen and (max-width: 500px){
    .header-home .second-section{
        padding: 2rem 1rem;
    }
}
@media screen and (max-width: 450px){
    .header-home .second-section div h3{
       font-size: 2.2rem;
    }
    .header-home .second-section div p{
        font-size: .9rem;
    }
}

.email-link{
    text-decoration: none;
    position: absolute;
    right: 0;
    top: 0;
    color: black;
    font-family: 'poppins';
    font-weight: 500;
    font-size: 1rem;
    transform: rotate(270deg);
    margin: 6rem -3rem 0rem 0rem;
}
