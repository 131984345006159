.recent-work-section{
        width: 100%;
        display: flex;
        justify-content: flex-start;
        background-color: #E5E6E8;
        padding: 2rem;
        position: relative;
        flex-direction: column;
}

.recent-work-section h3 {
    // font-family: variables.$font-bold;
    font-family: 'Philosopher';
    font-size: 3.2rem;
    color: black;
    font-weight: 400;
    text-align: center;
}
.recent-work-section .recent-work-videos{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8rem 0rem 4rem 0rem;
}
.recent-work-section .recent-work-video{
    // position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.recent-work-large-video{
    position: relative;
}
.recent-work-large-video > iframe{
    transition: opacity .5s;
}
.recent-work-section .recent-work-video .recent-work-small-video{
    width: 50%;
    position: absolute;
    top: 0;
    right: 0;
    margin-right: -5rem;
    margin-top: -5rem;
    transition: opacity .5s;

}
.recent-work-section .recent-work-video .recent-work-small-video > iframe{
    width: 100%;
    border-radius: 10px;
    box-shadow: 1px 1px 25px black;

}

.recent-work-button-div{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.recent-work-button{
    background-color: black;
    color: white;
    font-family: 'Poppins';
    border: 2px solid white;
    font-weight: 500;
    border-radius: 5px;
    padding: .8rem 4rem;
    text-decoration: none;
    transition: .5s;
}
.recent-work-button:hover{
    // color: black;
    // border: 2px solid black;
    background-color: #1c1c1c;
}