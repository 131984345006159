
/* .technologies-section .video-div{    
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
.technologies-section video{
    object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: 31;
}
.overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    heighzt: 100%;
    background-color: rgba(0, 0, 0, 0.63);
    z-index: 32;
} */
@use '../../_variables';

.services-section{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 8rem 2rem;
    width: 100%;
    /* background-color: #EDEADE; */
    /* background-color: #E9DCC9; */
    background-color: variables.$primary-color;
    /* flex-wrap: wrap; */
}
.services-section .heading-div{
    width: 100%;
    display: flex;
    justify-content: center;
    
}
.services-section .heading-div > h3{
    color: black;
    // font-family: variables.$font-bold;
    font-family: 'Philosopher';
    font-weight: 400;
    font-size: 3.2rem;
    margin-left: 1rem;
}

.services-section .services-div-container{
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    // align-items: center;
    width: 100%;
    flex-wrap: wrap;
    /* flex-direction: column; */
}
.services-section .services-div-container > div{
    margin: 5rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: center;
    width: 30%;
    padding: 2rem;
    background-size: cover;
    flex-direction: column;
    border: 2px solid teal;
     background-image: linear-gradient(rgb(0, 104, 104),rgb(0, 80, 80)); 
    // background-image: linear-gradient(rgb(17, 16, 16),rgb(17, 16, 16));

    /* background-image: linear-gradient(#A1CCD1 , #A1CCD1)  ; */
    /* background-image: linear-gradient(rgb(10, 75, 38),rgb(10, 75, 38)); */
    background-size: 0 100%;
    background-position: left;
    background-repeat: no-repeat;
    border-radius: 8px;

}


.add-bg{
    background-size: 100% 100% !important;
    transition: background-size .5s;
    color: black;
}

/* .services-section .services-div-container div.odd{
    border: 2px solid black;
    border-radius: 5px;
} */
/* .services-section .services-div-container div.odd h3{
    color: black;
}
.services-section .services-div-container div.odd p{
    color: black;
}
.services-section .services-div-container div.even{
    background-color: black;
}
.services-section .services-div-container div.even h3{
    color: white;
}
.services-section .services-div-container div.even p{
    color: white;
} */
.services-section .services-div-container > div > h3{
    // font-family: variables.$font-bold;
    font-family: 'Philosopher';
    font-size: 2rem;
    margin-top: 1rem;
    text-align: center;
    font-weight: 400;
}
.services-section .services-div-container > div > p{
    // font-family: variables.$font-regular;
    text-align: center;
    font-weight: 300;
    // font-size: 1.1rem;
   margin-top: 1rem;
   font-family: 'Poppins';
}