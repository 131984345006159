.about-header {
    background-image: url('../../assets/about-image.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    min-height: 100vh;

}

.about-header .overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: linear-gradient(rgba(68, 64, 64, 0.45),#000000ee);
    /* background: linear-gradient(#14ad9946,#000000 ); */
    z-index: 32;
} 

.about-header .first-section{
    width: 100%;
    padding: 2rem;
    z-index: 50;
}
.about-header .first-section .logo-div h2{
    color: white;
    font-size: 2rem;
    font-family: 'PT Serif';
    text-shadow: 2px 2px 4px black;
    font-weight: 400;
    z-index: 56;
    position: relative;
}
.about-header .first-section .links-icon-div{
    cursor: pointer;
    z-index: 56;
    position: relative;

}
.about-header .first-section .links-icon-div .links-icon-line{
    background-color: white;
    box-shadow: 2px 2px 4px black;
    margin: .2rem 0rem;
    height: 0.1rem;
    display: inline-block;   

}
.about-header .first-section .links-icon-div .links-icon .links-icon-first-line{
    width: 5rem;
}
.about-header .first-section .links-icon-div .links-icon .links-icon-second-line{
    width: 4rem;
}


.about-header .second-section{
    z-index: 50;
   width: 100%;
   position: relative;
   display: flex;
   justify-content: center;
   align-items: flex-end;
   padding: 2rem;
   margin-top: 8rem;
}

.about-header .second-section div{
   width: 70%;
   /* background-color: rgba(0, 0, 0, 0.671); */
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   padding: 2rem;
   border-radius: 8px;
}
.about-header .second-section div h3{
    color: white !important;
    font-family: 'PT Serif';
    font-weight: 400;
    font-size: 3rem;
    text-align: center;
    text-shadow: 2px 2px 4px rgba(46, 119, 129, 0.603);
    z-index: 56;
    position: relative;
}